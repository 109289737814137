import React from 'react';

import type { Course, Page, Document } from '@/payload/payload-types';

import { Link } from '@/navigation';
import { Button } from '../ButtonOld';

type CMSLinkType = {
	appearance?: unknown;
	children?: React.ReactNode;
	className?: string;
	label?: string;
	newTab?: boolean | null;
	reference?: {
		relationTo: 'courses' | 'pages' | 'documents';
		value: Course | Page | Document | string;
	} | null;
	type?: 'custom' | 'reference' | null;
	url?: string | null;
};

export const CMSLink: React.FC<CMSLinkType> = ({
	appearance,
	children,
	className,
	label,
	newTab,
	reference,
	type,
	url,
}) => {
	const href =
		type === 'reference' && typeof reference?.value === 'object' && reference.value.slug
			? `${reference?.relationTo !== 'pages' ? `/${reference?.relationTo}` : ''}/${
					reference.value.slug
				}`
			: url;

	if (!href) return null;

	if (!appearance) {
		const newTabProps = newTab ? { rel: 'noopener noreferrer', target: '_blank' } : {};
		if (href || url) {
			return (
				<Link
					{...newTabProps}
					className={className}
					href={{ params: { slug: href || (url as string) }, pathname: '[slug]' }}
				>
					{label && label}
					{children && children}
				</Link>
			);
		}
	}
	// @ts-expect-error refactor
	return <Button className={className} href={`${href}`} label={label} newTab={newTab} />;
};
