import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
}
const CalendarOutline = ({
	title,
	titleId,
	desc,
	descId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={25}
		fill="none"
		aria-labelledby={titleId}
		aria-describedby={descId}
		{...props}
	>
		{desc ? <desc id={descId}>{desc}</desc> : null}
		{title ? <title id={titleId}>{title}</title> : null}

		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.8125 2.44141V1.94141H16.8125V2.44141V3.44141H8.8125V2.44141V1.94141H7.8125V2.44141V3.44141H5.3125C4.62214 3.44141 4.0625 4.00105 4.0625 4.69141V7.94141V8.94141V19.6914C4.0625 20.3818 4.62214 20.9414 5.3125 20.9414H20.3125C21.0029 20.9414 21.5625 20.3818 21.5625 19.6914V8.94141V7.94141V4.69141C21.5625 4.00105 21.0029 3.44141 20.3125 3.44141H17.8125V2.44141ZM20.5625 7.94141V4.69141C20.5625 4.55334 20.4506 4.44141 20.3125 4.44141H17.8125V5.44141V5.94141H16.8125V5.44141V4.44141H8.8125V5.44141V5.94141H7.8125V5.44141V4.44141H5.3125C5.17443 4.44141 5.0625 4.55334 5.0625 4.69141V7.94141H20.5625ZM5.0625 8.94141V19.6914C5.0625 19.8295 5.17443 19.9414 5.3125 19.9414H20.3125C20.4506 19.9414 20.5625 19.8295 20.5625 19.6914V8.94141H5.0625Z"
			fill="#00318D"
		/>
	</svg>
);
export default CalendarOutline;
