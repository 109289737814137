import { useTranslations } from 'next-intl';
import Typography from '../Typography';
import { useCart } from '../../_providers/Cart';

export const CartTotal: React.FC = () => {
	const t = useTranslations('Cart');
	const { cartTotal, cartTotalVat, cartHasVat } = useCart();

	return (
		<div>
			<div className="flex items-center justify-between">
				<Typography.Paragraph size="lg" bold>
					{t('total')}
				</Typography.Paragraph>
				<Typography.Paragraph size="lg" bold>
					{cartTotal.formatted}
				</Typography.Paragraph>
			</div>
			<Typography.Paragraph size="sm">
				{cartHasVat
					? t('purchase-includes-vat', { totalVat: cartTotalVat.formatted })
					: t('purchase-does-not-include-vat')}
			</Typography.Paragraph>
		</div>
	);
};
