import * as React from 'react';
import { SVGProps } from 'react';
interface SVGRProps {
	title?: string;
	titleId?: string;
	desc?: string;
	descId?: string;
}
const Trash = ({ title, titleId, desc, descId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={25}
		height={25}
		fill="none"
		aria-labelledby={titleId}
		aria-describedby={descId}
		{...props}
	>
		{desc ? <desc id={descId}>{desc}</desc> : null}
		{title ? <title id={titleId}>{title}</title> : null}

		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.5625 2.19141C9.96576 2.19141 9.39347 2.42846 8.97151 2.85042C8.54955 3.27237 8.3125 3.84467 8.3125 4.44141V5.19141H6.0625H4.5625C4.14829 5.19141 3.8125 5.52719 3.8125 5.94141C3.8125 6.35562 4.14829 6.69141 4.5625 6.69141H5.3125V20.1914C5.3125 20.5892 5.47054 20.9708 5.75184 21.2521C6.03315 21.5334 6.41468 21.6914 6.8125 21.6914H18.8125C19.2103 21.6914 19.5919 21.5334 19.8732 21.2521C20.1545 20.9708 20.3125 20.5892 20.3125 20.1914V6.69141H21.0625C21.4767 6.69141 21.8125 6.35562 21.8125 5.94141C21.8125 5.52719 21.4767 5.19141 21.0625 5.19141H19.5625H17.3125V4.44141C17.3125 3.84467 17.0754 3.27237 16.6535 2.85042C16.2315 2.42846 15.6592 2.19141 15.0625 2.19141H10.5625ZM15.8125 5.19141V4.44141C15.8125 4.24249 15.7335 4.05173 15.5928 3.91108C15.4522 3.77042 15.2614 3.69141 15.0625 3.69141H10.5625C10.3636 3.69141 10.1728 3.77042 10.0322 3.91108C9.89152 4.05173 9.8125 4.24249 9.8125 4.44141V5.19141H15.8125ZM9.0625 6.69141H6.8125V20.1914H18.8125V6.69141H16.5625H9.0625ZM10.5625 9.69141C10.9767 9.69141 11.3125 10.0272 11.3125 10.4414V16.4414C11.3125 16.8556 10.9767 17.1914 10.5625 17.1914C10.1483 17.1914 9.8125 16.8556 9.8125 16.4414V10.4414C9.8125 10.0272 10.1483 9.69141 10.5625 9.69141ZM15.8125 16.4414V10.4414C15.8125 10.0272 15.4767 9.69141 15.0625 9.69141C14.6483 9.69141 14.3125 10.0272 14.3125 10.4414V16.4414C14.3125 16.8556 14.6483 17.1914 15.0625 17.1914C15.4767 17.1914 15.8125 16.8556 15.8125 16.4414Z"
			fill="#00318D"
		/>
	</svg>
);
export default Trash;
