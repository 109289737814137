'use client';
import React, { useEffect } from 'react';
import * as CookieConsent from 'vanilla-cookieconsent';
import pluginConfig from './CookieConsentConfig';

import './index.css';
import { useTranslations } from 'next-intl';
import { Button } from '../ButtonOld';

const CookieConsentComponent = () => {
	useEffect(() => {
		CookieConsent.run(pluginConfig);
	}, []);
	const t = useTranslations();

	return (
		<Button
			size="small"
			variant="ghost"
			intent="tertiary"
			onClick={CookieConsent.showPreferences}
		>
			{t('show-cookie-preferences')}
		</Button>
	);
};
export default CookieConsentComponent;
