import { useLocale, useTranslations } from 'next-intl';
import { Course, Courseversion } from '@/payload/payload-types';
import { CounterInput } from '@shadcn/components/ui/input';
import dayjs from 'dayjs';
import { Link } from '@/navigation';

import React, { Fragment } from 'react';
import { useCart } from '../../_providers/Cart';
import { formatPrice } from '../../_utilities/format-price';
import { Chip } from '../Chip';
import { CartItem as ICartItem } from '../../_providers/Cart/reducer';
import { Button } from '../ButtonOld';
import Trash from '../../_icons/Trash';
import { Skeleton } from '@shadcn/components/ui/skeleton';
import Typography from '../../_components/Typography';

import CarlendarOutline from '../../_icons/CalendarOutline';

const PriceChip: React.FC<{
	discountedPrice?: number;
	normalPrice: number;
	locale: string;
	discountCodeApplied?: boolean | null;
}> = ({ discountedPrice, normalPrice, locale, discountCodeApplied }) => {
	return discountedPrice && !discountCodeApplied ? (
		<Typography.Title level={3}>{formatPrice(discountedPrice, locale, 'EUR')}</Typography.Title>
	) : (
		<Typography.Title level={3}>{formatPrice(normalPrice, locale, 'EUR')}</Typography.Title>
	);
};

type CartItemType = { item: Courseversion; cartItem: ICartItem; disableCounter?: boolean };

//TODO: rename price chip to price
export const CartItem: React.FC<CartItemType> = ({ item, cartItem, disableCounter }) => {
	const locale = useLocale();
	const t = useTranslations('Cart');
	const [seatInputValue, setSeatInputValue] = React.useState<number>(cartItem.quantity as number);
	const { addItemToCart, deleteItemFromCart, isCartSyncInProgress } = useCart();

	React.useEffect(() => {
		addItemToCart({ ...cartItem, quantity: seatInputValue });
	}, [seatInputValue]);

	const normalPrice = cartItem.quantity ? cartItem.price! * cartItem.quantity : cartItem.price!;
	const discountedPrice = cartItem.quantity
		? cartItem.discountedPrice! * cartItem.quantity
		: cartItem.discountedPrice!;
	const course = item.course as Course;

	return (
		<div key={item.id} className="border-ebs-golden-500 flex flex-col border">
			<div className="border-b-ebs-golden-500 bg-ebs-golden-100 border p-6">
				<div className="grid w-full grid-cols-[2fr_1fr] gap-6">
					<div className="flex flex-col space-y-2">
						<Link
							href={{ pathname: '/courses/[slug]', params: { slug: course.slug! } }}
							key={course.id}
						>
							<Typography.Title link level={3}>
								{course.title}
							</Typography.Title>
						</Link>

						<div className="flex items-center space-x-2">
							<CarlendarOutline />
							<Typography.Paragraph size="md">
								{`${dayjs(item.timeEdit.startDate).format('DD.MM.YYYY')}-${dayjs(item.timeEdit.endDate).format('DD.MM.YYYY')}`}
							</Typography.Paragraph>
						</div>
					</div>
					<div className="flex items-center space-x-6">
						{!disableCounter && (
							<div className="flex w-fit items-center">
								<CounterInput
									isLoading={isCartSyncInProgress}
									setValue={setSeatInputValue}
									type="number"
									value={seatInputValue}
									maxCount={item.remainingSlots || 10} // 10 - needs business decision
								/>
							</div>
						)}

						<PriceChip
							discountedPrice={discountedPrice}
							normalPrice={normalPrice}
							locale={locale}
							discountCodeApplied={cartItem.discountCodeApplied}
						/>

						<Button
							onClick={() => deleteItemFromCart(cartItem)}
							disabled={isCartSyncInProgress}
							variant="icon"
						>
							<Trash />
						</Button>
					</div>
				</div>
			</div>
			<div>siia tuleb osaleja info</div>
		</div>
	);
};
