'use client';

import React from 'react';
import { CartShoppingBag } from '@/app/(frontend)/_components/CartShoppingBag';
import { Link } from '@/navigation';

export const CartLink: React.FC<{
	className?: string;
}> = () => {
	return (
		<Link href="/cart">
			<CartShoppingBag />
		</Link>
	);
};
